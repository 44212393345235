import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../Styles/TransferCertificate.css";
import SearchHeader from "./SearchHeader";
import TcDataComponent from "./TcDataComponent";
import {
	addDataInDocument,
	deleteDataInDocument,
	getDataFromCollection,
	showErrorToast,
	showSuccessToast,
	updateDataInDocument,
	uploadPdf,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import AddEditTransferCertificate from "./AddEditTransferCertificate";
import DeletePopup from "./DeletePopup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};

const TransferCertificate = () => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [editData, setEditData] = useState({
		id: "",
		name: "",
		admissionNumber: "",
		dob: "",
		pdf: "",
	});
	const [deleteData, setDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const data = await getDataFromCollection(
				COLLECTIONS.TRANSFER_CERTIFICATE
			);
			console.log("data :>> ", data);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	const handleSave = async (data) => {
		try {
			setButtonLoading(true);
			console.log("Data in handleSave", data);
			if (editData.id) {
				let preparedData = { ...data };

				if (data?.pdf && data.pdf?.lastModified) {
					const pdfUrl = await uploadPdf(data.pdf);
					console.log("pdfUrl", pdfUrl);

					preparedData = {
						...data,
						pdf: {
							name: data.pdf.name,
							url: pdfUrl,
						},
					};
				}

				await updateDataInDocument(
					COLLECTIONS.TRANSFER_CERTIFICATE,
					editData.id,
					preparedData
				);
			} else {
				const pdfUrl = await uploadPdf(data.pdf);
				console.log("pdfUrl", pdfUrl);

				const preparedData = {
					...data,
					pdf: {
						name: data.pdf.name,
						url: pdfUrl,
					},
				};

				await addDataInDocument(COLLECTIONS.TRANSFER_CERTIFICATE, preparedData);
			}

			showSuccessToast("Data saved successfully");

			closeModal();
		} catch (error) {
			console.log("Error in handleSave", error);
			showErrorToast("Error saving data");
		} finally {
			setButtonLoading(false);
			getData();
		}
	};

	const handleEdit = (data) => {
		setEditData(data);
		setIsOpen(true);
	};

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(
				COLLECTIONS.TRANSFER_CERTIFICATE,
				deleteData.id
			);
			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
			getData();
		} catch (error) {
			console.log("Error in handleDelete", error);
			showErrorToast("Error deleting data");
		}
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	return (
		<div className="container">
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}

			<SearchHeader
				mainTitle="Issue of Transfer Certificate"
				text={""}
				onChangeText={() => {}}
			/>

			<div className="row mb-5">
				<div className="col-12">
					<button
						className="btn btn-dark subjects"
						style={{
							padding: "12px 40px",
						}}
						onClick={openModal}
					>
						{" "}
						+ Add TC
					</button>
				</div>
			</div>

			<TcDataComponent
				handleDelete={handleDeletePopup}
				handleEdit={handleEdit}
				data={listData}
			/>

			<Modal isOpen={modalIsOpen} style={customStyles}>
				<AddEditTransferCertificate
					handleSave={handleSave}
					handleCancel={() => {
						closeModal();
						setEditData({
							id: "",
							name: "",
							admissionNumber: "",
							dob: "",
							pdf: "",
						});
					}}
					data={editData}
					buttonLoading={buttonLoading}
				/>
			</Modal>
		</div>
	);
};
export default TransferCertificate;
