import React from "react";
import img2 from "../Images/pencil.png";
import img3 from "../Images/Vector.png";

const SubjectsDataComponent = ({
	handleDelete = () => {},
	handleEdit = () => {},
	data = [],
}) => {
	if (!data.length) {
		return null;
	}

	return (
		<div className="mt-4">
			<div className="table-responsive">
				<form>
					<table>
						<thead
							className="rounded-top"
							style={{ backgroundColor: "#cce6ff" }}
						>
							<tr>
								<th
									style={{
										borderTopLeftRadius: "16px",
										width: "5%",
									}}
								>
									<input
										type="checkbox"
										id="vehicle1"
										name="vehicle1"
										value="Bike"
									></input>
								</th>
								<th
									style={{
										width: "15%",
									}}
								>
									Standard
								</th>
								<th
									style={{
										width: "45%",
									}}
									className="name"
								>
									Subject Offered
								</th>
								<th
									style={{
										width: "20%",
									}}
								>
									Year
								</th>
								<th
									style={{
										borderTopRightRadius: "16px",
										width: "15%",
									}}
								/>
							</tr>
						</thead>
						<tbody>
							{data.map((student) => {
								return (
									<tr
										key={student.id}
										style={{
											borderBottom: "1px solid #A4A4A4",
											marginTop: "20px",
											height: "80px",
										}}
									>
										<td>
											<input
												type="checkbox"
												id="vehicle1"
												name="vehicle1"
												value="Bike"
											/>
										</td>
										<td>{student.standard}</td>
										<td className="name">{student.subjectOffer}</td>
										<td>{student.year}</td>
										<td>
											<button
												style={{
													background: "transparent",
													border: "none",
												}}
												onClick={(e) => {
													e.preventDefault();
													handleEdit(student);
												}}
											>
												<img src={img2} alt="" />
											</button>
											<button
												style={{
													background: "transparent",
													border: "none",
												}}
												onClick={(e) => {
													e.preventDefault();
													handleDelete(student);
												}}
											>
												<img src={img3} alt="" />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</form>
			</div>
		</div>
	);
};

export default SubjectsDataComponent;
