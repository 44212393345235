import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytes,
} from "firebase/storage";
import {
	addDoc,
	collection,
	deleteDoc,
	getDoc,
	getDocs,
	setDoc,
	query,
	where,
	orderBy,
	limit,
	doc,
} from "firebase/firestore";
import { db, firebaseStorage } from "./firebaseConfig";
import { toast } from "react-toastify";

const toastConfig = {
	position: "top-right",
	autoClose: 1000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
};

export const uploadImage = async (file) => {
	try {
		if (!file) {
			return null;
		}

		const [fileName, fileExtension] = file.name.split(".");
		const finalFileName = `${fileName}_${Date.now()}.${fileExtension}`;

		const storageRef = ref(firebaseStorage, "images/" + finalFileName);
		await uploadBytes(storageRef, file);
		const url = await getDownloadURL(storageRef);
		return url;
	} catch (error) {
		console.log("Error uploading image", error);
		throw error;
	}
};

export const deleteResource = async (url) => {
	try {
		const storageRef = ref(firebaseStorage, url);
		await deleteObject(storageRef);
	} catch (error) {
		console.log("Error deleting image", error);
		throw error;
	}
};

export const uploadPdf = async (file) => {
	try {
		if (!file) {
			return null;
		}

		const [fileName, fileExtension] = file.name.split(".");
		const finalFileName = `${fileName}_${Date.now()}.${fileExtension}`;
		const storageRef = ref(firebaseStorage, "pdfs/" + finalFileName);
		await uploadBytes(storageRef, file);
		const url = await getDownloadURL(storageRef);
		return url;
	} catch (error) {
		console.log("Error uploading pdf", error);
		throw error;
	}
};

// Add a document to a collection
export const addDataInDocument = async (collectionName, data) => {
	try {
		const docRef = await addDoc(collection(db, collectionName), data);
		console.log("Document written with ID: ", docRef.id);
	} catch (error) {
		console.error("Error adding document: ", error);
		throw error;
	}
};

// Update a document in a collection
export const updateDataInDocument = async (collectionName, id, data) => {
	try {
		const docRef = doc(db, collectionName, id);
		await setDoc(docRef, data, { merge: true });
		console.log("Document updated");
	} catch (error) {
		console.error("Error updating document: ", error);
		throw error;
	}
};

export const deleteDataInDocument = async (collectionName, id) => {
	try {
		const docRef = doc(db, collectionName, id);

		await deleteDoc(docRef);
		console.log("Document deleted");
	} catch (error) {
		console.error("Error deleting document: ", error);
		throw error;
	}
};

export const getDataFromCollection = async (collectionName) => {
	try {
		const querySnapshot = await getDocs(collection(db, collectionName));
		const data = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		return data;
	} catch (error) {
		console.error("Error getting documents: ", error);
		throw error;
	}
};

export const getDataFromCollectionWithQuery = async (
	collectionName,
	filters = [],
	orderByField = null,
	orderDirection = "asc",
	limitResults = null
) => {
	try {
		// Create a base query
		let q = query(collection(db, collectionName));

		// Apply filters
		filters.forEach((filter) => {
			const { field, operator, value } = filter;
			q = query(q, where(field, operator, value));
		});

		// Apply ordering
		if (orderByField) {
			q = query(q, orderBy(orderByField, orderDirection));
		}

		// Apply limit
		if (limitResults) {
			q = query(q, limit(limitResults));
		}

		// Execute the query
		const querySnapshot = await getDocs(q);
		const data = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		return data;
	} catch (error) {
		console.error("Error getting documents with query: ", error);
		throw error;
	}
};

export const getDataFromDocument = async (docRef) => {
	try {
		const doc = await getDoc(docRef);
		return doc.data();
	} catch (error) {
		console.error("Error getting document: ", error);
		throw error;
	}
};

export const formatDate = (dateString) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const dateParts = dateString.split("-");
	const year = dateParts[0];
	const month = months[parseInt(dateParts[1], 10) - 1];
	const day = parseInt(dateParts[2], 10);

	return `${month} ${day < 10 ? "0" : ""}${day}, ${year}`;
};

export const getStudyYearsDropdown = () => {
	const currentYear = new Date().getFullYear();

	const options = [
		{
			label: `${currentYear - 1}-${currentYear.toString().slice(-2)}`,
			value: `${currentYear - 1}-${currentYear.toString().slice(-2)}`,
		},
		{
			label: `${currentYear}-${(currentYear + 1).toString().slice(-2)}`,
			value: `${currentYear}-${(currentYear + 1).toString().slice(-2)}`,
		},
	];

	return options;
};

export const showSuccessToast = (message) => {
	toast.success(message, toastConfig);
};

export const showErrorToast = (message) => {
	toast.error(message, toastConfig);
};

export const showInfoToast = (message) => {
	toast.info(message, toastConfig);
};
