import React, { useCallback, useEffect, useState } from "react";
import "../Styles/Results.css";
import SearchHeader from "./SearchHeader";

import Modal from "react-modal";
import StudentForm from "./StudentForm";
import {
	addDataInDocument,
	deleteDataInDocument,
	deleteResource,
	getDataFromCollection,
	getDataFromCollectionWithQuery,
	getStudyYearsDropdown,
	showErrorToast,
	showSuccessToast,
	updateDataInDocument,
	uploadImage,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import ResultsDataComponent from "./ResultsDataComponent";
import DeletePopup from "./DeletePopup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		height: "90%",
	},
};

const currentYear = new Date().getFullYear();

const Results = () => {
	const [dropdownData, setDropdownData] = useState([]);
	const [dropdownValue, setDropdownValue] = useState("");
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
	const [listsData, setListsData] = useState({
		tenthStd: [],
		twelfthStdScience: [],
		twelfthStdCommerce: [],
		twelfthStdArts: [],
	});
	const [editData, setEditData] = useState({
		id: "",
		name: "",
		class: "10",
		stream: "",
		percentage: "",
		rank: "",
		image: null,
		year: `${currentYear - 1}-${currentYear.toString().slice(-2)}`,
	});
	const [deleteData, setDeleteData] = useState(null);
	const [showAddButton, setShowAddButton] = useState(false);

	useEffect(() => {
		fetchDropdownData();
		manageAddButton();
	}, []);

	const manageAddButton = async () => {
		try {
			const allData = await getDataFromCollection(COLLECTIONS.RANK);
			if (allData.length < 5) {
				setShowAddButton(true);
			} else {
				setShowAddButton(false);
			}
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const getData = useCallback(async () => {
		try {
			const tenthStudentsData = await getDataFromCollectionWithQuery(
				COLLECTIONS.RANK,
				[
					{
						field: "class",
						operator: "==",
						value: "10",
					},
					{
						field: "year",
						operator: "==",
						value: dropdownValue,
					},
				],
				"rank"
			);

			const twelfthStdScienceData = await getDataFromCollectionWithQuery(
				COLLECTIONS.RANK,
				[
					{
						field: "class",
						operator: "==",
						value: "12",
					},
					{
						field: "stream",
						operator: "==",
						value: "science",
					},
					{
						field: "year",
						operator: "==",
						value: dropdownValue,
					},
				],
				"rank"
			);

			const twelfthStdCommerceData = await getDataFromCollectionWithQuery(
				COLLECTIONS.RANK,
				[
					{
						field: "class",
						operator: "==",
						value: "12",
					},
					{
						field: "stream",
						operator: "==",
						value: "commerce",
					},
					{
						field: "year",
						operator: "==",
						value: dropdownValue,
					},
				],
				"rank"
			);

			const twelfthStdArtsData = await getDataFromCollectionWithQuery(
				COLLECTIONS.RANK,
				[
					{
						field: "class",
						operator: "==",
						value: "12",
					},
					{
						field: "stream",
						operator: "==",
						value: "arts",
					},
					{
						field: "year",
						operator: "==",
						value: dropdownValue,
					},
				],
				"rank"
			);

			setListsData({
				tenthStd: tenthStudentsData,
				twelfthStdScience: twelfthStdScienceData,
				twelfthStdCommerce: twelfthStdCommerceData,
				twelfthStdArts: twelfthStdArtsData,
			});
		} catch (error) {
			console.log("Error fetching data", error);
		}
	}, [dropdownValue]);

	useEffect(() => {
		if (dropdownValue) getData();
	}, [dropdownValue, getData]);

	const fetchDropdownData = () => {
		const data = getStudyYearsDropdown();
		setDropdownData(data);
		setDropdownValue(data[0].value);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setEditData({
			id: "",
			name: "",
			class: "10",
			stream: "",
			percentage: "",
			rank: "",
			image: null,
			year: `${currentYear - 1}-${currentYear.toString().slice(-2)}`,
		});
	};

	const handleSave = async (data) => {
		try {
			setButtonLoading(true);
			let imageUrl = null;

			if (isImageChanged) {
				imageUrl = await uploadImage(data.image);
			}

			if (editData.id) {
				let preparedData = { ...data };

				if (isImageChanged) {
					if (editData.image) await deleteResource(editData.image);
					preparedData.image = imageUrl;
				} else {
					const { image, ...restData } = preparedData;
					preparedData = restData;
				}

				console.log("edit preparedData", preparedData);
				await updateDataInDocument(COLLECTIONS.RANK, editData.id, preparedData);
			} else {
				const preparedData = {
					...data,
					image: imageUrl,
				};

				console.log("preparedData", preparedData);
				await addDataInDocument(COLLECTIONS.RANK, preparedData);
			}

			showSuccessToast("Data saved successfully");
			closeModal();
		} catch (error) {
			console.log("Error saving data", error);
			showErrorToast("Error saving data");
		} finally {
			setButtonLoading(false);
			await getData();
		}
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(COLLECTIONS.RANK, deleteData.id);
			await deleteResource(deleteData.image);
			await getData();
			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
		} catch (error) {
			console.log("Error deleting document: ", error);
			showErrorToast("Error deleting document");
		}
	};

	const handleEdit = (data) => {
		setEditData(data);
		openModal();
	};

	return (
		<div className="container">
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}
			<SearchHeader mainTitle="Result Update" />

			<div className="result">
				<div className="row">
					<div className="col-6">
						<form action="">
							<label htmlFor="ranking-year">Ranking Year:</label>
							<select
								id="ranking-year"
								name="ranking-year"
								onChange={(e) => {
									setDropdownValue(e.target.value);
								}}
							>
								{dropdownData.map((year, index) => (
									<option key={index} value={year.value}>
										{year.label}
									</option>
								))}
							</select>
						</form>
					</div>

					<div className="col-6">
						{showAddButton && (
							<button className="btn btn-dark rank" onClick={openModal}>
								+ Add Student Rank
							</button>
						)}
					</div>
				</div>

				<ResultsDataComponent
					handleDelete={handleDeletePopup}
					handleEdit={handleEdit}
					title="12th Class (Science)"
					data={listsData.twelfthStdScience}
				/>

				<ResultsDataComponent
					handleDelete={handleDeletePopup}
					handleEdit={handleEdit}
					title="12th Class (Commerce)"
					data={listsData.twelfthStdCommerce}
				/>

				<ResultsDataComponent
					handleDelete={handleDeletePopup}
					handleEdit={handleEdit}
					title="12th Class (Arts)"
					data={listsData.twelfthStdArts}
				/>

				<ResultsDataComponent
					handleDelete={handleDeletePopup}
					handleEdit={handleEdit}
					title="10th Class"
					data={listsData.tenthStd}
				/>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles}>
				<StudentForm
					handleSave={handleSave}
					handleCancel={closeModal}
					setIsImageChanged={setIsImageChanged}
					buttonLoading={buttonLoading}
					data={editData}
				/>
			</Modal>
		</div>
	);
};
export default Results;
