import React, { useState, useEffect, useMemo } from "react";
import "../Styles/PdfUpload.css";
import pdf from "../Images/pdf.png";

const PdfUpload = ({ pdfName: initialPdfName, onFileChange }) => {
	const [pdfName, setPdfName] = useState(initialPdfName || "Add your PDF");

	useEffect(() => {
		setPdfName(initialPdfName || "Add your PDF");
	}, [initialPdfName]);

	const handleFileChange = (event) => {
		if (event.target.files.length > 0) {
			const fileName = event.target.files[0].name;
			setPdfName(fileName);
			if (onFileChange) {
				onFileChange(event.target.files[0]);
			}
		}
	};

	const pdfNameToShow = useMemo(() => {
		if (typeof pdfName === "string") {
			return pdfName;
		}

		return pdfName ? pdfName.name : "Add your PDF";
	}, [pdfName]);

	return (
		<div>
			<label>Add PDF</label> <br />
			<div className="upload-box">
				<label htmlFor="pdf-upload" className="upload-label">
					{pdfNameToShow}
				</label>
				<input
					type="file"
					id="pdf-upload"
					accept="application/pdf"
					onChange={handleFileChange}
					className="upload-input"
				/>
				<div className="pdf-icon">
					<img src={pdf} alt="" />
				</div>
			</div>
		</div>
	);
};

export default PdfUpload;
