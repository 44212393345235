import React from "react";

const Button = ({
	className = "",
	onClick = () => {},
	loading = false,
	disabled = false,
	children,
}) => {
	return (
		<button
			className={className}
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
			disabled={disabled || loading}
		>
			{loading ? "Loading..." : children}
		</button>
	);
};

export default Button;
