import React from "react";
import img2 from "../Images/pencil.png";
import img3 from "../Images/Vector.png";
import pdf from "../Images/pdf.png";

const CommonDataComponent = ({
	handleDelete = () => {},
	handleEdit = () => {},
	data = [],
}) => {
	if (!data.length) {
		return null;
	}

	return (
		<div className="mt-5">
			<div className="table-responsive">
				<form>
					<table>
						<thead
							className="rounded-top"
							style={{ backgroundColor: "#cce6ff" }}
						>
							<tr>
								<th
									style={{
										borderTopLeftRadius: "16px",
										width: "10%",
									}}
								>
									Sr. No
								</th>
								<th
									style={{
										width: "40%",
									}}
								>
									Document Name
								</th>
								<th
									style={{
										width: "40%",
									}}
								>
									PDF
								</th>
								<th
									style={{
										borderTopRightRadius: "16px",
									}}
								/>
							</tr>
						</thead>
						<tbody>
							{data.map((fee, index) => {
								return (
									<tr
										key={fee.id}
										style={{
											borderBottom: "1px solid #A4A4A4",
											marginTop: "20px",
											height: "80px",
										}}
									>
										<td>{index + 1}</td>
										<td>{fee.documentName}</td>
										<td>
											{fee.pdf ? fee.pdf.name : ""}
											<img src={pdf} alt="" />
										</td>
										<td>
											<button
												style={{
													background: "transparent",
													border: "none",
												}}
												onClick={(e) => {
													e.preventDefault();
													handleEdit(fee);
												}}
											>
												<img src={img2} alt="" />
											</button>
											<button
												style={{
													background: "transparent",
													border: "none",
												}}
												onClick={(e) => {
													e.preventDefault();
													handleDelete(fee);
												}}
											>
												<img src={img3} alt="" />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</form>
			</div>
		</div>
	);
};

export default CommonDataComponent;
