import React from "react";
import "../Styles/DeletePopup.css";

const DeletePopup = ({ onDeleteConfirm, onDeleteCancel }) => {
	return (
		<div className="delete-popup">
			<div className="delete-popup-content">
				<h3>Do you want to delete?</h3>
				<div className="delete-popup-buttons">
					<button
						className="delete-btn delete-popup-yes"
						onClick={(e) => {
							e.preventDefault();
							onDeleteConfirm();
						}}
					>
						Yes
					</button>
					<button
						className="delete-btn delete-popup-no"
						onClick={(e) => {
							e.preventDefault();
							onDeleteCancel();
						}}
					>
						No
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeletePopup;
