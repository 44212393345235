import React, { useState } from "react";
import "../Styles/TransferCertificateData.css";
import Button from "./Button";
import PdfUpload from "./PdfUpload";

const CommonAddEditData = ({
	handleSave = (data) => {},
	handleCancel = () => {},
	buttonLoading = false,
	data = {},
	title = "",
}) => {
	const [values, setValues] = useState({
		documentName: data.documentName || "",
		pdf: data.pdf || "",
	});

	const handleValueChange = (key, value) => {
		setValues((prevData) => ({
			...prevData,
			[key]: value,
		}));
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<h1 className="text-dark mt-2">
						{data.id ? "Edit" : "Add"} {title}
					</h1>
					<form>
						<div className="form-group">
							<label htmlFor="">Document Name</label> <br />
							<input
								className="mt-0"
								type="text"
								style={{
									padding: "15px",
									width: "100%",
								}}
								value={values.documentName}
								onChange={(e) =>
									handleValueChange("documentName", e.target.value)
								}
							/>
						</div>

						<PdfUpload
							pdfName={values.pdf}
							onFileChange={(file) => handleValueChange("pdf", file)}
						/>

						{/* 
						<div className="form-group">
							<label>Add PDf</label>
							<br />
							<input type="file" placeholder="Add your pdf" accept=".pdf" />
						</div> */}
						<div className="buttons mr-0">
							<Button
								className="btn cancel"
								onClick={handleCancel}
								disabled={buttonLoading}
							>
								Cancel
							</Button>
							<Button
								className="btn submit"
								onClick={() => handleSave(values)}
								loading={buttonLoading}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CommonAddEditData;
