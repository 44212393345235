import React, { useState } from "react";
import "../Styles/TransferCertificateData.css";
import Button from "./Button";
import PdfUpload from "./PdfUpload";

const AddEditTransferCertificate = ({
	handleSave = (data) => {},
	handleCancel = () => {},
	buttonLoading = false,
	data = {},
}) => {
	const [values, setValues] = useState({
		name: data.name || "",
		admissionNumber: data.admissionNumber || "",
		dob: data.dob || "",
		pdf: data.pdf || "",
	});

	const handleValueChange = (key, value) => {
		setValues((prevData) => ({
			...prevData,
			[key]: value,
		}));
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<h1 className="text-dark mt-2">
						{data.id ? "Edit" : "Add"} Transfer Certificate
					</h1>
					<form>
						<div className="form-group">
							<label htmlFor="">Student Name</label> <br />
							<input
								className="mt-0"
								type="text"
								style={{
									padding: "15px",
									width: "100%",
								}}
								value={values.name}
								onChange={(e) => handleValueChange("name", e.target.value)}
							/>
						</div>

						<div className=" container form-group">
							<div className="row">
								<div className="col-6 p-0">
									<label>Admission Number</label> <br />
									<input
										className="mt-0"
										type="number"
										style={{
											padding: "15px",
											width: "100%",
										}}
										value={values.admissionNumber}
										onChange={(e) =>
											handleValueChange("admissionNumber", e.target.value)
										}
									/>
								</div>
								<div className="col-6 pr-0">
									<label>Date Of Birth</label> <br />
									<input
										className="mt-0"
										type="date"
										style={{
											padding: "15px",
											width: "100%",
										}}
										value={values.dob}
										onChange={(e) => handleValueChange("dob", e.target.value)}
									/>
								</div>
							</div>
						</div>

						<PdfUpload
							pdfName={values.pdf}
							onFileChange={(file) => handleValueChange("pdf", file)}
						/>

						{/* 
						<div className="form-group">
							<label>Add PDf</label>
							<br />
							<input type="file" placeholder="Add your pdf" accept=".pdf" />
						</div> */}
						<div className="buttons mr-0">
							<Button
								className="btn cancel"
								onClick={handleCancel}
								disabled={buttonLoading}
							>
								Cancel
							</Button>
							<Button
								className="btn submit"
								onClick={() => handleSave(values)}
								loading={buttonLoading}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddEditTransferCertificate;
