export const PAGE_ROUTES = {
	HOME: "/",
	SUBJECTS_OFFERED: "/subjects-offered",
	INQUIRIES: "/inquiries",
	ISSUE_OF_TC: "/issue-of-tc",
	FEES_STRUCTURE: "/fees-structure",
	MANDATORY_DISCLOSURE: "/mandatory-disclosure",
	CBSE_CIRCULAR: "/cbse-circular",
	STAFF_LIST: "/staff-list",
	LOGIN: "/login",
	CONTACTS: "/contacts",
};

export const CLASS_DATA = [
	{
		value: "10",
		label: "10th",
	},
	{
		value: "12",
		label: "12th",
	},
];

export const STREAM_DATA = [
	{
		value: "science",
		label: "Science",
	},
	{
		value: "commerce",
		label: "Commerce",
	},
	{
		value: "arts",
		label: "Arts",
	},
];

export const COLLECTIONS = {
	RANK: "rank",
	SUBJECTS: "subjects",
	FEES: "fees",
	TRANSFER_CERTIFICATE: "transfer_certificate",
	MANDATORY_DISCLOSURE: "mandatory_disclosure",
	CBSE_CIRCULAR: "cbse_circular",
	STAFF_LIST: "staff_list",
	CONTACTS: "contacts",
	FRC_ORDER: "frc_order",
	INQUIRIES: "inquiries",
};

export const USER = {
	email: "admin@modern.com",
	password: "@{rhqQpaKw3i;S&u'Uyx",
};
