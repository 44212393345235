import React, { useState } from "react";
import "../Styles/TransferCertificateData.css";
import Button from "./Button";

const AddFeesStructure = ({
	handleSave = (data) => {},
	handleCancel = () => {},
	buttonLoading = false,
	data = {},
}) => {
	const [values, setValues] = useState({
		stdName: data.stdName || "",
		annualFees: data.annualFees || "",
		admissionFees: data.admissionFees || "",
	});

	const handleValueChange = (key, value) => {
		setValues((prevData) => ({
			...prevData,
			[key]: value,
		}));
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<h1 className="text-dark mt-2">{data.id ? "Edit" : "Add"} Fees</h1>
					<form>
						<div className="form-group">
							<label htmlFor="">Standard Name</label> <br />
							<input
								className="mt-0"
								type="text"
								style={{
									padding: "15px",
									width: "100%",
								}}
								value={values.stdName}
								onChange={(e) => handleValueChange("stdName", e.target.value)}
							/>
						</div>

						<div className=" container">
							<div className="row">
								<div className="col-6 p-0">
									<label>Annual Fees</label> <br />
									<input
										className="mt-0"
										type="number"
										style={{
											padding: "15px",
											width: "100%",
										}}
										value={values.annualFees}
										onChange={(e) =>
											handleValueChange("annualFees", e.target.value)
										}
									/>
								</div>
								<div className="col-6 pr-0">
									<label>Admission Fees</label> <br />
									<input
										className="mt-0"
										type="number"
										style={{
											padding: "15px",
											width: "100%",
										}}
										value={values.admissionFees}
										onChange={(e) =>
											handleValueChange("admissionFees", e.target.value)
										}
									/>
								</div>
							</div>
						</div>
						{/* 
						<div className="form-group">
							<label>Add PDf</label>
							<br />
							<input type="file" placeholder="Add your pdf" accept=".pdf" />
						</div> */}
						<div className="buttons mr-0">
							<Button
								className="btn cancel"
								onClick={handleCancel}
								disabled={buttonLoading}
							>
								Cancel
							</Button>
							<Button
								className="btn submit"
								onClick={() => handleSave(values)}
								loading={buttonLoading}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddFeesStructure;
