import React, { createContext, useState, useContext, useEffect } from "react";
import "./utils/firebaseConfig";
import {
	createBrowserRouter,
	RouterProvider,
	Outlet,
	Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideNav from "./Components/SideNav";
import Results from "./Components/Results";
import SubjectOffered from "./Components/SubjectOffered";
import Inquiries from "./Components/Inquiries";
import TransferCertificate from "./Components/TransferCertificate";
import FeesStructure from "./Components/FeesStructure";
import Login from "./Components/Login";
import { PAGE_ROUTES } from "./utils/constants";
import CommonListScreen from "./Components/CommonListScreen";
import Contacts from "./Components/Contacts";
import Cookies from "js-cookie";

const AuthContext = createContext();

const Layout = () => (
	<div className="d-flex">
		<div className="col-2 p-0">
			<SideNav mainTitle={"Inquiry"} />
		</div>
		<div
			className="col-10"
			style={{
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<Outlet />
		</div>
	</div>
);

const ProtectedRoute = ({ children }) => {
	const { isAuthenticated, isAuthChecked } = useContext(AuthContext);

	if (!isAuthChecked) {
		return <div>Loading...</div>; // or a loading spinner
	}

	return isAuthenticated ? children : <Navigate to={PAGE_ROUTES.LOGIN} />;
};

const router = createBrowserRouter([
	{
		path: PAGE_ROUTES.HOME,
		element: (
			<ProtectedRoute>
				<Layout />
			</ProtectedRoute>
		),
		children: [
			{
				index: true,
				element: <Results />,
			},
			{
				path: PAGE_ROUTES.SUBJECTS_OFFERED,
				element: <SubjectOffered />,
			},
			{
				path: PAGE_ROUTES.INQUIRIES,
				element: <Inquiries />,
			},
			{
				path: PAGE_ROUTES.ISSUE_OF_TC,
				element: <TransferCertificate />,
			},
			{
				path: PAGE_ROUTES.FEES_STRUCTURE,
				element: <FeesStructure />,
			},
			{
				path: PAGE_ROUTES.MANDATORY_DISCLOSURE,
				element: <CommonListScreen />,
			},
			{
				path: PAGE_ROUTES.CBSE_CIRCULAR,
				element: <CommonListScreen />,
			},
			{
				path: PAGE_ROUTES.STAFF_LIST,
				element: <CommonListScreen />,
			},
			{
				path: PAGE_ROUTES.CONTACTS,
				element: <Contacts />,
			},
		],
	},
	{
		path: PAGE_ROUTES.LOGIN,
		element: <Login />,
	},
]);

const App = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthChecked, setIsAuthChecked] = useState(false);

	useEffect(() => {
		const user = Cookies.get("user");
		if (user) {
			setIsAuthenticated(true);
		}
		setIsAuthChecked(true); // Indicate that auth check is complete
	}, []);

	return (
		<AuthContext.Provider
			value={{ isAuthenticated, setIsAuthenticated, isAuthChecked }}
		>
			<RouterProvider router={router} />
			<ToastContainer />
		</AuthContext.Provider>
	);
};

export default App;
export { AuthContext };
