import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import "../Styles/MandatoryDisclosuer.css";
import SearchHeader from "./SearchHeader";
import CommonDataComponent from "./CommonDataComponent";
import CommonAddEditData from "./CommonAddEditData";
import {
	addDataInDocument,
	deleteDataInDocument,
	deleteResource,
	getDataFromCollection,
	showErrorToast,
	showSuccessToast,
	updateDataInDocument,
	uploadPdf,
} from "../utils/helper";
import { COLLECTIONS, PAGE_ROUTES } from "../utils/constants";
import DeletePopup from "./DeletePopup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};

export default function CommonListScreen() {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [editData, setEditData] = useState({
		id: "",
		documentName: "",
		pdf: "",
	});
	const [deleteData, setDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	const location = useLocation();

	const { collectionName, title } = useMemo(() => {
		switch (location.pathname) {
			case PAGE_ROUTES.MANDATORY_DISCLOSURE:
				return {
					collectionName: COLLECTIONS.MANDATORY_DISCLOSURE,
					title: "Mandatory Disclosure",
				};
			case PAGE_ROUTES.CBSE_CIRCULAR:
				return {
					collectionName: COLLECTIONS.CBSE_CIRCULAR,
					title: "CBSE Circular",
				};
			case PAGE_ROUTES.STAFF_LIST:
				return {
					collectionName: COLLECTIONS.STAFF_LIST,
					title: "Staff List",
				};
			default:
				return {
					collectionName: COLLECTIONS.MANDATORY_DISCLOSURE,
					title: "Mandatory Disclosure",
				};
		}
	}, [location.pathname]);

	const getData = useCallback(async () => {
		try {
			const data = await getDataFromCollection(collectionName);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	}, [collectionName]);

	const handleSave = async (data) => {
		try {
			setButtonLoading(true);
			if (editData.id) {
				let preparedData = { ...data };

				if (data?.pdf && data.pdf?.lastModified) {
					const pdfUrl = await uploadPdf(data.pdf);

					preparedData = {
						...data,
						pdf: {
							name: data.pdf.name,
							url: pdfUrl,
						},
					};

					await deleteResource(editData.pdf.url);
				}

				await updateDataInDocument(collectionName, editData.id, preparedData);
			} else {
				const pdfUrl = await uploadPdf(data.pdf);

				const preparedData = {
					...data,
					pdf: {
						name: data.pdf.name,
						url: pdfUrl,
					},
				};

				await addDataInDocument(collectionName, preparedData);
			}

			showSuccessToast("Data saved successfully");
			closeModal();
		} catch (error) {
			console.log("Error in handleSave", error);
			showErrorToast("Error saving data");
		} finally {
			setButtonLoading(false);
			getData();
		}
	};

	const handleEdit = (data) => {
		setEditData(data);
		setIsOpen(true);
	};

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(collectionName, deleteData.id);
			await deleteResource(deleteData.pdf.url);

			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
			getData();
		} catch (error) {
			console.log("Error in handleDelete", error);
			showErrorToast("Error deleting data");
		}
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	useEffect(() => {
		getData();
	}, [collectionName, getData]);

	return (
		<div className="container">
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}
			<SearchHeader mainTitle={title} text={""} onChangeText={() => {}} />

			{((collectionName === COLLECTIONS.STAFF_LIST && listData.length === 0) ||
				collectionName === COLLECTIONS.MANDATORY_DISCLOSURE ||
				collectionName === COLLECTIONS.CBSE_CIRCULAR) && (
				<div div className="row">
					<div className="col-12">
						<button onClick={openModal} className="btn btn-dark subjects">
							{" "}
							+ Add Document
						</button>
					</div>
				</div>
			)}

			<CommonDataComponent
				data={listData}
				handleDelete={handleDeletePopup}
				handleEdit={handleEdit}
			/>

			<Modal isOpen={modalIsOpen} style={customStyles}>
				<CommonAddEditData
					handleSave={handleSave}
					handleCancel={() => {
						closeModal();
						setEditData({
							id: "",
							name: "",
							admissionNumber: "",
							dob: "",
							pdf: "",
						});
					}}
					data={editData}
					buttonLoading={buttonLoading}
					title={title}
				/>
			</Modal>
		</div>
	);
}
