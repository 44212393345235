import React from "react";

const SearchHeader = ({ mainTitle, onChangeText }) => {
	return (
		<div className="heading">
			{/* <div className="search">
				<label htmlFor="search"></label>
				<input
					type="text"
					id="search"
					placeholder="Search"
					onChange={onChangeText}
				/>
			</div> */}
			<h1 className="text-dark mb-0">{mainTitle}</h1>
		</div>
	);
};

export default SearchHeader;
