import React, { useCallback, useEffect, useState } from "react";
import "../Styles/Inquiries.css";
import img2 from "../Images/Vector.png";
import SearchHeader from "./SearchHeader";
import {
	deleteDataInDocument,
	formatDate,
	getDataFromCollection,
	showSuccessToast,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import DeletePopup from "./DeletePopup";

export default function Inquiries() {
	const [inquiriesData, setInquiriesData] = useState([]);
	const [deleteData, setDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	const getData = useCallback(async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.INQUIRIES);

			setInquiriesData(data);
		} catch (error) {
			console.log("error in getting data", error);
		}
	}, []);

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(COLLECTIONS.INQUIRIES, deleteData.id);

			getData();

			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
		} catch (error) {
			console.error("Error deleting document: ", error);
		}
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<div>
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}

			<div className="ml-3 mb-4">
				<SearchHeader mainTitle="Inquiries" text={""} onChangeText={() => {}} />
			</div>

			{inquiriesData.length > 0 && (
				<table striped bordered hover>
					<thead>
						<th>Child Name</th>
						<th>Class</th>
						<th>Date Of Birth</th>
						<th>Contact</th>
						<th>Email Address</th>
					</thead>
					<tbody>
						{inquiriesData.map((data) => {
							return (
								<tr key={data.id}>
									<td>{data.name}</td>
									<td>{data.class}</td>
									<td>{formatDate(data.dob)}</td>

									<td>{data.phone}</td>
									<td>{data.email}</td>
									<td>
										<img
											src={img2}
											alt=""
											onClick={() => handleDeletePopup(data)}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
}
