import React, { useState } from "react";
import "../Styles/SubjectForm.css";
import Button from "./Button";

const SubjectForm = ({
	handleSave = (data) => {},
	handleCancel = () => {},
	buttonLoading = false,
	data = {},
}) => {
	const [values, setValues] = useState({
		standard: data.standard || "",
		year: data.year || "",
		subjectOffer: data.subjectOffer || "",
	});

	const handleValueChange = (key, value) => {
		setValues((prevData) => ({
			...prevData,
			[key]: value,
		}));
	};

	return (
		<div className="container">
			<div className="row">
				<form className="px-4 pb-4">
					<h1 className="mt-0">{data.id ? "Edit" : "Add"} Subject</h1>

					<div className="row">
						<div className="col-6 form-group">
							<label className="mb-0">Standard</label>
							<br />
							<input
								type="text"
								style={{
									width: "100%",
									padding: "15px",
									borderRadius: "10px",
									border: "1px solid #ccc",
								}}
								value={values.standard}
								onChange={(e) => handleValueChange("standard", e.target.value)}
							/>
						</div>

						<div className="col-6 form-group">
							<label className="mb-0">Year</label>
							<input
								className="form-control"
								type="text"
								style={{
									width: "100%",
									padding: "15px",
									borderRadius: "10px",
								}}
								value={values.year}
								onChange={(e) => handleValueChange("year", e.target.value)}
							/>
						</div>
					</div>

					<div className="form-group formSubjects">
						<label>Subject Offer</label>
						<br />
						<textarea
							as="textarea"
							rows={8}
							cols={94}
							className="form-control p-3"
							style={{
								padding: "4px",
								borderRadius: "10px",
								border: "1px solid #ccc",
							}}
							value={values.subjectOffer}
							onChange={(e) =>
								handleValueChange("subjectOffer", e.target.value)
							}
						/>
					</div>

					<div className="buttons">
						<Button
							className="btn cancel"
							onClick={handleCancel}
							disabled={buttonLoading}
						>
							Cancel
						</Button>
						<Button
							className="btn submit"
							onClick={() => handleSave(values)}
							loading={buttonLoading}
						>
							Save
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SubjectForm;
