import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import img1 from "../Images/logo1.png";
import "../Styles/Login.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import { PAGE_ROUTES, USER } from "../utils/constants";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState({});
	const { setIsAuthenticated } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (Cookies.get("user")) {
			navigate(PAGE_ROUTES.HOME);
		}
	}, [navigate]);

	const validate = () => {
		let tempErrors = {};
		let isValid = true;

		if (email !== USER.email) {
			tempErrors["email"] = "Invalid email";
			isValid = false;
		}

		if (password !== USER.password) {
			tempErrors["password"] = "Invalid password";
			isValid = false;
		}

		setErrors(tempErrors);
		return isValid;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validate()) {
			Cookies.set("user", email, { expires: 1 });
			setIsAuthenticated(true);
			navigate("/");
		}
	};

	return (
		<div className="container login">
			<div className="row">
				<div className="col-6">
					<img src={img1} alt="logo" />
					<h1>Modern Public School Najibabad</h1>
				</div>

				<div className="col-6">
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<h1>LOGIN</h1>
							<label htmlFor="InputEmail1">Email </label>
							<input
								type="email"
								className={`form-control ${errors.email ? "is-invalid" : ""}`}
								id="exampleInputEmail1"
								aria-describedby="emailHelp"
								placeholder="henna.green@test.com"
								style={{ backgroundColor: "#FAFAFA", padding: "10px 5px" }}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{errors.email && (
								<div className="invalid-feedback">{errors.email}</div>
							)}
						</div>
						<div className="form-group">
							<label htmlFor="InputPassword1">Password</label>
							<input
								type="password"
								className={`form-control ${
									errors.password ? "is-invalid" : ""
								}`}
								id="InputPassword1"
								placeholder="Password123@"
								style={{ backgroundColor: "#FAFAFA", padding: "10px 5px" }}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{errors.password && (
								<div className="invalid-feedback">{errors.password}</div>
							)}
						</div>
						<button type="submit" className="btn login-in">
							LOGIN
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
