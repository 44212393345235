import React, { useCallback, useEffect, useState } from "react";
import deleteImg from "../Images/Vector.png";
import SearchHeader from "./SearchHeader";
import {
	deleteDataInDocument,
	getDataFromCollection,
	showErrorToast,
	showSuccessToast,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import DeletePopup from "./DeletePopup";

export default function Contacts() {
	const [contactsData, setContactsData] = useState([]);
	const [deleteData, setDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	const getData = useCallback(async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.CONTACTS);
			setContactsData(data);
		} catch (error) {
			console.log("Error in Contacts", error);
		}
	}, []);

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(COLLECTIONS.CONTACTS, deleteData.id);

			showSuccessToast("Contact deleted successfully");
			closeDeletePopup();

			getData();
		} catch (error) {
			console.log("Error deleting document: ", error);
			showErrorToast("Error deleting contact");
		}
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<div>
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}

			<SearchHeader mainTitle="Contacts" text={""} onChangeText={() => {}} />
			{contactsData.length > 0 && (
				<table striped bordered hover>
					<thead>
						<th
							style={{
								width: "20%",
							}}
						>
							Name
						</th>
						<th
							style={{
								width: "20%",
							}}
						>
							Class
						</th>
						<th
							style={{
								width: "35%",
							}}
						>
							Message
						</th>
						<th
							style={{
								width: "20%",
							}}
						>
							Phone
						</th>
						<th
							style={{
								width: "5%",
							}}
						/>
					</thead>
					<tbody>
						{contactsData.map((item) => (
							<tr key={item.id} className="border-bottom">
								<td
									style={{
										paddingBottom: "10px",
										paddingTop: "10px",
									}}
								>
									{item.name}
								</td>
								<td>{item.admissionClass}</td>
								<td>{item.message}</td>

								<td>{item.phone}</td>
								<td>
									<button
										style={{
											background: "transparent",
											border: "none",
										}}
										onClick={(e) => {
											e.preventDefault();
											handleDeletePopup(item);
										}}
									>
										<img src={deleteImg} alt="" />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
