import React, { useMemo } from "react";
import "../Styles/ImageUpload.css";

const ImageUpload = ({ image, setImage }) => {
	const handleChange = (e) => {
		if (e.target.files[0]) {
			setImage(e.target.files[0]);
		}
	};

	const imageUrl = useMemo(() => {
		if (typeof image === "string") {
			return image;
		}

		if (image) {
			return URL.createObjectURL(image);
		}

		return null;
	}, [image]);

	return (
		<div className="image-upload-container">
			{image ? (
				<div
					style={{
						position: "relative",
					}}
				>
					<button className="remove-image" onClick={() => setImage(null)}>
						❌
					</button>

					<img
						src={imageUrl}
						alt="Uploaded"
						width={150}
						className="uploaded-image"
					/>
				</div>
			) : (
				<div className="upload-placeholder">
					<div className="upload-icon">&#128247;</div>
					<p>No image uploaded yet</p>
					<label htmlFor="fileInput" className="select-image">
						Select Image
					</label>
					<input
						type="file"
						id="fileInput"
						accept="image/*"
						onChange={handleChange}
						style={{ display: "none" }}
						multiple={false}
					/>
					<p>5 mb Max</p>
				</div>
			)}
		</div>
	);
};

export default ImageUpload;
