import React, { useCallback, useEffect, useState } from "react";
import "../Styles/StudentForm.css";
import Dropdown from "./Dropdown";
import { CLASS_DATA, STREAM_DATA } from "../utils/constants";
import ImageUpload from "./ImageUpload";
import Button from "./Button";
import { getStudyYearsDropdown } from "../utils/helper";

export default function StudentForm({
	handleSave = () => {},
	handleCancel = () => {},
	buttonLoading = false,
	data = {
		name: "",
		class: "10",
		stream: "",
		percentage: "",
		rank: "",
		image: null,
		year: null,
	},
	setIsImageChanged = () => {},
}) {
	const [values, setValues] = useState({
		name: data.name,
		class: data.class,
		stream: data.stream,
		percentage: data.percentage,
		rank: data.rank,
		image: data.image,
		year: data.year,
	});
	const [dropdownData, setDropdownData] = useState([]);

	const fetchYearsDropdownData = useCallback(() => {
		const data = getStudyYearsDropdown();
		setDropdownData(data);
	}, []);

	useEffect(() => {
		fetchYearsDropdownData();
	}, [fetchYearsDropdownData]);

	const handleValueChange = (key, value) => {
		setValues((prevData) => ({
			...prevData,
			[key]: value,
		}));

		if (key === "class" && value === "12") {
			setValues((prevData) => ({
				...prevData,
				stream: "science",
			}));
		}
	};

	return (
		<div className="container py-3">
			<h2
				className="mb-4"
				style={{
					fontWeight: "bold",
				}}
			>
				{data.id ? "Edit" : "Add"} Student Rank
			</h2>
			<div className="row">
				<div className="col p-0">
					<form>
						<div className="row mt-4">
							<div className="col-6">
								<label htmlFor="name">Student Name</label>
								<input
									name="name"
									id="name"
									type="text"
									className="p-3 m-0"
									style={{
										width: "100%",
									}}
									value={values.name}
									onChange={(e) => {
										handleValueChange("name", e.target.value);
									}}
								/>
							</div>
							<div className="col-6">
								<label htmlFor="year">Year</label> <br />
								<Dropdown
									options={dropdownData}
									selectedValue={values.year}
									setSelectedValue={(value) => {
										handleValueChange("year", value);
									}}
									containerStyle={{
										width: "96%",
									}}
								/>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-6">
								<label>Class</label> <br />
								<Dropdown
									options={CLASS_DATA}
									selectedValue={values.class}
									setSelectedValue={(value) => {
										handleValueChange("class", value);
									}}
								/>
							</div>
							<div className="col-6">
								<label>Stream</label> <br />
								<Dropdown
									options={STREAM_DATA}
									selectedValue={values.stream}
									setSelectedValue={(value) => {
										handleValueChange("stream", value);
									}}
									disabled={values.class === "10"}
									containerStyle={{
										width: "96%",
									}}
								/>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-6">
								<label htmlFor="percentage">Percentage</label> <br />
								<input
									id="percentage"
									type="text"
									className="p-3 m-0"
									placeholder=""
									style={{
										width: "100%",
									}}
									value={values.percentage}
									onChange={(e) => {
										handleValueChange(
											"percentage",
											e.target.value.replace(/[^0-9.]/g, "")
										);
									}}
									maxLength={5}
								/>
							</div>
							<div className="col-6">
								<label htmlFor="rank">Rank</label> <br />
								<input
									id="rank"
									type="text"
									className="p-3 mt-0"
									placeholder=""
									style={{
										width: "96%",
									}}
									value={values.rank}
									onChange={(e) => {
										handleValueChange(
											"rank",
											e.target.value.replace(/[^0-9.]/g, "")
										);
									}}
									maxLength={3}
								/>
							</div>
						</div>

						<div className="form-group mt-4">
							<label>Student Image</label>
							<br />
							<ImageUpload
								image={values.image}
								setImage={(value) => {
									setIsImageChanged(true);
									handleValueChange("image", value);
								}}
							/>
						</div>
						<div className="buttons">
							<Button
								className="btn cancel"
								onClick={handleCancel}
								disabled={buttonLoading}
							>
								Cancel
							</Button>
							<Button
								className="btn submit"
								onClick={() => handleSave(values)}
								loading={buttonLoading}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
