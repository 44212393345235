import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../Styles/TransferCertificate.css";
import SearchHeader from "./SearchHeader";
import FeesDataComponent from "./FeesDataComponent";
import AddTransferCertificate from "./AddFeesStructure";
import {
	addDataInDocument,
	deleteDataInDocument,
	deleteResource,
	getDataFromCollection,
	showErrorToast,
	showSuccessToast,
	updateDataInDocument,
	uploadPdf,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import CommonAddEditData from "./CommonAddEditData";
import FrcDataComponent from "./FrcDataComponent";
import DeletePopup from "./DeletePopup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
	},
};

function FeesStructure() {
	const [isAddEditFrc, setIsAddEditFrc] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [frcButtonLoading, setFrcButtonLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [frcData, setFrcData] = useState([]);
	const [editData, setEditData] = useState({
		id: "",
		stdName: "",
		annualFees: "",
		admissionFees: "",
	});
	const [deleteData, setDeleteData] = useState(null);
	const [frcDeleteData, setFrcDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	useEffect(() => {
		getData();
		getFrcData();
	}, []);

	const getData = async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.FEES);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	const getFrcData = async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.FRC_ORDER);
			console.log("frc data :>> ", data);
			setFrcData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleSave = async (data) => {
		try {
			setButtonLoading(true);
			console.log("Data in handleSave", data);
			if (editData.id) {
				await updateDataInDocument(COLLECTIONS.FEES, editData.id, data);
			} else {
				await addDataInDocument(COLLECTIONS.FEES, data);
			}

			showSuccessToast("Data saved successfully");
			closeModal();
		} catch (error) {
			console.log("Error in handleSave", error);
			showErrorToast("Error saving data");
		} finally {
			setButtonLoading(false);
			getData();
		}
	};

	const handleEdit = (data) => {
		setEditData(data);
		openModal();
	};

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(COLLECTIONS.FEES, deleteData.id);
			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
			getData();
		} catch (error) {
			console.log("Error in handleDelete", error);
			showErrorToast("Error deleting data");
		}
	};

	const handleFrcSave = async (data) => {
		try {
			setFrcButtonLoading(true);
			const pdfUrl = await uploadPdf(data.pdf);

			const preparedData = {
				...data,
				pdf: {
					name: data.pdf.name,
					url: pdfUrl,
				},
			};

			await addDataInDocument(COLLECTIONS.FRC_ORDER, preparedData);
			showSuccessToast("Data saved successfully");
			setIsAddEditFrc(false);
		} catch (error) {
			console.log("Error in handleSave", error);
			showErrorToast("Error saving data");
		} finally {
			setFrcButtonLoading(false);
			getFrcData();
		}
	};

	const handleFrcDelete = async () => {
		try {
			await deleteResource(frcDeleteData.pdf.url);

			await deleteDataInDocument(COLLECTIONS.FRC_ORDER, frcDeleteData.id);

			showSuccessToast("Data deleted successfully");

			closeDeletePopup();
			getFrcData();
		} catch (error) {
			console.log("Error in handleDelete", error);
		}
	};

	const handleFrcDeletePopup = (data) => {
		setFrcDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
		setFrcDeleteData(null);
	};

	return (
		<div className="container">
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={deleteData ? handleDelete : handleFrcDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}
			<SearchHeader mainTitle="FRC Order" text={""} onChangeText={() => {}} />
			{frcData.length <= 0 && (
				<div className="row">
					<div className="col-12">
						<button
							className="btn btn-dark subjects"
							onClick={() => {
								setIsAddEditFrc(true);
							}}
						>
							+ Add
						</button>
					</div>
				</div>
			)}

			<FrcDataComponent data={frcData} handleDelete={handleFrcDeletePopup} />

			<SearchHeader
				mainTitle="Fees Structure"
				text={""}
				onChangeText={() => {}}
			/>

			<div className="row mb-4">
				<div className="col-12">
					<button className="btn btn-dark subjects" onClick={openModal}>
						+ Add
					</button>
				</div>
			</div>

			<FeesDataComponent
				data={listData}
				handleEdit={handleEdit}
				handleDelete={handleDeletePopup}
			/>

			<Modal isOpen={modalIsOpen} style={customStyles}>
				<AddTransferCertificate
					handleSave={handleSave}
					handleCancel={() => {
						closeModal();
						setEditData({
							id: "",
							stdName: "",
							annualFees: "",
							admissionFees: "",
						});
					}}
					data={editData}
					buttonLoading={buttonLoading}
				/>
			</Modal>

			<Modal isOpen={isAddEditFrc} style={customStyles}>
				<CommonAddEditData
					handleSave={handleFrcSave}
					handleCancel={() => {
						setIsAddEditFrc(false);
					}}
					data={{
						documentName: "FRC Order",
						pdf: "",
					}}
					buttonLoading={frcButtonLoading}
					title={"FRC Order"}
				/>
			</Modal>
		</div>
	);
}

export default FeesStructure;
