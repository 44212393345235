import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../Styles/SubjectOffered.css";
import SearchHeader from "./SearchHeader";
import SubjectsDataComponent from "./SubjectsDataComponent";
import SubjectForm from "./SubjectForm";
import {
	addDataInDocument,
	deleteDataInDocument,
	getDataFromCollectionWithQuery,
	showErrorToast,
	showSuccessToast,
	updateDataInDocument,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import DeletePopup from "./DeletePopup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		height: "84%",
	},
};

export default function SubjectOffered() {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [editData, setEditData] = useState({
		id: "",
		standard: "",
		year: "",
		subjectOffer: "",
	});
	const [deleteData, setDeleteData] = useState(null);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const data = await getDataFromCollectionWithQuery(
				COLLECTIONS.SUBJECTS,
				[],
				"standard"
			);
			console.log("list data :>> ", data);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setEditData({
			id: "",
			standard: "",
			year: "",
			subjectOffer: "",
		});
	};

	const handleSave = async (data) => {
		try {
			setButtonLoading(true);
			console.log("Data in handleSave", data);
			if (editData.id) {
				await updateDataInDocument(COLLECTIONS.SUBJECTS, editData.id, data);
			} else {
				await addDataInDocument(COLLECTIONS.SUBJECTS, data);
			}

			showSuccessToast("Data saved successfully");

			closeModal();
		} catch (error) {
			console.log("Error in handleSave", error);
			showErrorToast("Error saving data");
		} finally {
			setButtonLoading(false);
			getData();
		}
	};

	const handleEdit = (data) => {
		setEditData(data);
		openModal();
	};

	const handleDelete = async () => {
		try {
			await deleteDataInDocument(COLLECTIONS.SUBJECTS, deleteData.id);
			getData();
			showSuccessToast("Data deleted successfully");
			closeDeletePopup();
		} catch (error) {
			console.log("Error in handleDelete", error);
			showErrorToast("Error deleting data");
		}
	};

	const handleDeletePopup = (data) => {
		setDeleteData(data);
		setIsDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setIsDeletePopupOpen(false);
		setDeleteData(null);
	};

	return (
		<div className="container">
			{isDeletePopupOpen && (
				<DeletePopup
					onDeleteConfirm={handleDelete}
					onDeleteCancel={closeDeletePopup}
				/>
			)}
			<SearchHeader
				mainTitle="Subject Offered"
				text={""}
				onChangeText={() => {}}
			/>
			<div className="row">
				<div className="col-12">
					<button className="btn btn-dark subjects" onClick={openModal}>
						{" "}
						+ Add Subject
					</button>
				</div>
			</div>

			<SubjectsDataComponent
				handleDelete={handleDeletePopup}
				handleEdit={handleEdit}
				data={listData}
			/>

			<Modal isOpen={modalIsOpen} style={customStyles}>
				<SubjectForm
					handleCancel={closeModal}
					data={editData}
					handleSave={handleSave}
					buttonLoading={buttonLoading}
				/>
			</Modal>
		</div>
	);
}
