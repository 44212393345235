import React, { useMemo } from "react";
import "../Styles/SideNav.css";

import home from "../Images/home.png";
import homeActive from "../Images/home-active.png";
import bookStack from "../Images/books-stack.png";
import bookStackActive from "../Images/books-stack-active.png";
import user from "../Images/user.png";
import userActive from "../Images/user-active.png";
import certificate from "../Images/certificate.png";
import certificateActive from "../Images/certificate-active.png";
import rupee from "../Images/rupee.png";
import rupeeActive from "../Images/rupee-active.png";
import megaPhone from "../Images/megaphone.png";
import megaPhoneActive from "../Images/megaphone-active.png";
import docs from "../Images/docs.png";
import docsActive from "../Images/docs-active.png";
import list from "../Images/list.png";
import listActive from "../Images/list-active.png";
import Logo from "../Images/Logo.png";

import { Link, useLocation } from "react-router-dom";
import { PAGE_ROUTES } from "../utils/constants";

const SideNav = () => {
	const location = useLocation();

	const [
		isHome,
		isSubjectsOffered,
		isInquiries,
		isIssueOfTC,
		isFeesStructure,
		isMandatoryDisclosure,
		isCbseCircular,
		isStaffList,
		isContacts,
	] = useMemo(() => {
		const pathName = location.pathname;
		return [
			pathName === PAGE_ROUTES.HOME,
			pathName === PAGE_ROUTES.SUBJECTS_OFFERED,
			pathName === PAGE_ROUTES.INQUIRIES,
			pathName === PAGE_ROUTES.ISSUE_OF_TC,
			pathName === PAGE_ROUTES.FEES_STRUCTURE,
			pathName === PAGE_ROUTES.MANDATORY_DISCLOSURE,
			pathName === PAGE_ROUTES.CBSE_CIRCULAR,
			pathName === PAGE_ROUTES.STAFF_LIST,
			pathName === PAGE_ROUTES.CONTACTS,
		];
	}, [location]);

	return (
		<div
			className="border-right"
			style={{
				height: "100vh",
				overflowY: "auto",
			}}
		>
			<div className="d-flex align-items-center justify-content-center">
				<img src={Logo} height={124} alt="Logo" />
			</div>
			<div className="d-flex side-nav">
				<div className="p-2">
					<ul className="nav flex-column">
						<li className="nav-item">
							<Link to={"/"} className="nav-link p-0">
								<img src={isHome ? homeActive : home} alt="" />
								<span className={isHome ? "active" : ""}>Result Update</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/subjects-offered"} className="nav-link p-0">
								<img
									src={isSubjectsOffered ? bookStackActive : bookStack}
									alt=""
								/>
								<span className={isSubjectsOffered ? "active" : ""}>
									Subject Offered
								</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/inquiries"} className="nav-link p-0">
								<img src={isInquiries ? userActive : user} alt="" />
								<span className={isInquiries ? "active" : ""}>Inquiries</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/issue-of-tc"} className="nav-link p-0">
								<img
									src={isIssueOfTC ? certificateActive : certificate}
									alt=""
								/>
								<span className={isIssueOfTC ? "active" : ""}>Issue of TC</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/fees-structure"} className="nav-link p-0">
								<img src={isFeesStructure ? rupeeActive : rupee} alt="" />
								<span className={isFeesStructure ? "active" : ""}>
									Fees Structure
								</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/mandatory-disclosure"} className="nav-link p-0">
								<img
									src={isMandatoryDisclosure ? megaPhoneActive : megaPhone}
									alt=""
								/>
								<span className={isMandatoryDisclosure ? "active" : ""}>
									Mandatory Disclosure
								</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/cbse-circular"} className="nav-link p-0">
								<img src={isCbseCircular ? docsActive : docs} alt="" />
								<span className={isCbseCircular ? "active" : ""}>
									CBSE Circulars
								</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link to={"/staff-list"} className="nav-link p-0">
								<img src={isStaffList ? listActive : list} alt="" />
								<span className={isStaffList ? "active" : ""}>Staff List</span>
							</Link>
						</li>
						<li className="nav-item mt-2">
							<Link
								to={"/contacts"}
								className="nav-link p-0 d-flex align-items-center"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 28 28"
									fill="currentColor"
									className="size-2"
									width={28}
									height={28}
									style={{
										margin: "0 15px 0 15px",
									}}
								>
									<path
										fillRule="evenodd"
										d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
										clipRule="evenodd"
										fill={isContacts ? "#1814F3" : ""}
									/>
								</svg>
								<span className={isContacts ? "active" : ""}>Contacts</span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SideNav;
