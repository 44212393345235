import React from "react";
import img3 from "../Images/Vector.png";

const FrcDataComponent = ({ handleDelete = () => {}, data = [] }) => {
	if (!data.length) {
		return null;
	}

	return (
		<div className="mt-4">
			<div className="table-responsive">
				<form>
					<table>
						<thead
							className="rounded-top"
							style={{ backgroundColor: "#cce6ff" }}
						>
							<tr>
								<th
									style={{
										borderTopLeftRadius: "16px",
										width: "60%",
									}}
								>
									Document Name
								</th>
								<th
									style={{
										width: "25%",
									}}
								>
									PDF
								</th>
								<th
									style={{
										borderTopRightRadius: "16px",
									}}
								/>
							</tr>
						</thead>
						<tbody>
							{data.map((fee) => {
								return (
									<tr
										key={fee.id}
										style={{
											borderBottom: "1px solid #A4A4A4",
											marginTop: "20px",
											height: "80px",
										}}
									>
										<td>{fee.documentName}</td>
										<td>{fee.pdf.name}</td>
										<td>
											<button
												style={{
													background: "transparent",
													border: "none",
												}}
												onClick={(e) => {
													e.preventDefault();
													handleDelete(fee);
												}}
											>
												<img src={img3} alt="" />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</form>
			</div>
		</div>
	);
};

export default FrcDataComponent;
