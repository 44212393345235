import React from "react";

const Dropdown = ({
	options,
	selectedValue,
	setSelectedValue,
	label,
	id,
	name,
	className,
	style,
	containerStyle,
	...rest
}) => {
	return (
		<div className="custom-dropdown" style={containerStyle}>
			<select
				id={id}
				name={name}
				className={className ? className : "p-3 m-0"}
				style={{
					border: "1px solid #D1D1D1",
					borderRadius: "10px",
					fontSize: "16px",
					width: "100%",
					...style,
				}}
				value={selectedValue}
				onChange={(e) => setSelectedValue(e.target.value)}
				{...rest}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default Dropdown;
